
































import { SOCIAL_SELECTORS } from '@/constants'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({}) socials!: any
  getSocialIcon(uniqName) {
    const social = SOCIAL_SELECTORS.find((social) => social.uniqName === uniqName)
    return social?.url || ''
  }
  openWindow(link) {
    window.open(link, '_blank')
  }
}
